import React, { useState } from 'react'
import axios from 'axios'
import './AddProductPage.css'

const AddProductPage = () => {
  const [name, setName] = useState('')
  const [game, setGame] = useState('')
  const [price, setPrice] = useState('')
  const [costPrice, setCostPrice] = useState('')
  const [image, setImage] = useState('')
  const [isOnSale, setIsOnSale] = useState(false)
  const [isHidden, setIsHidden] = useState(false)
  const [makeBroadcast, setMakeBroadcast] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Проверка на пустые поля
    if (!name || !game || !price || !image || !costPrice) {
      alert('Пожалуйста, заполните все поля')
      return
    }

    try {
      let costPriceNumber = Number(costPrice)
      let priceNumber = Number(price)
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/add-product`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name,
            game,
            costPrice: costPriceNumber,
            price: priceNumber,
            image,
            isOnSale,
            isHidden,
            makeBroadcast,
          }),
        }
      )

      if (!response.ok) {
        throw new Error('Ошибка при добавлении продукта')
      }

      const data = await response.json()

      alert(data.message)
    } catch (error) {
      console.error(error)
      alert('Ошибка при добавлении продукта')
    }
  }

  return (
    <form onSubmit={handleSubmit} className="add-product-form">
      <div className="admin-container">
        <label className="admin-title" htmlFor="name">
          Название товара
        </label>
        <input
          className="admin-input"
          id="name"
          type="text"
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Название"
          required
        />
      </div>
      <div className="admin-container">
        <label className="admin-title" htmlFor="game">
          Игра
        </label>
        <select
          className="admin-input"
          id="game"
          value={game}
          onChange={(e) => setGame(e.target.value)}
          required
        >
          <option value="Brawl Stars">Brawl Stars</option>
          <option value="Clash Royale">Clash Royale</option>
          <option value="Clash of Clans">Clash of Clans</option>
          <option value="Roblox">Roblox</option>
        </select>
      </div>
      <div className="admin-container">
        <label className="admin-title" htmlFor="costPrice">
          Себестоимость
        </label>
        <input
          className="admin-input"
          id="costPrice"
          type="number"
          value={costPrice}
          onChange={(e) => setCostPrice(e.target.value)}
          placeholder="Себестоимость"
          required
        />
      </div>
      <div className="admin-container">
        <label className="admin-title" htmlFor="price">
          Цена
        </label>
        <input
          className="admin-input"
          id="price"
          type="number"
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder="Цена"
          required
        />
      </div>
      <div className="admin-container">
        <label className="admin-title" htmlFor="image">
          URL обложки товара
        </label>
        <input
          className="admin-input"
          id="image"
          type="text"
          value={image}
          onChange={(e) => setImage(e.target.value)}
          placeholder="URL"
          required
        />
      </div>
      <label
        htmlFor="isOnSale"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <input
          className="admin-input"
          id="isOnSale"
          type="checkbox"
          checked={isOnSale}
          onChange={(e) => setIsOnSale(e.target.checked)}
        />
        <span className="admin-title">Акция?</span>
      </label>

      <label
        htmlFor="isHidden"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <input
          id="isHidden"
          type="checkbox"
          checked={isHidden}
          onChange={(e) => setIsHidden(e.target.checked)}
        />
        <span className="admin-title">Скрыть товар</span>
      </label>

      <label
        htmlFor="makeBroadcast"
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <input
          className="admin-input"
          id="makeBroadcast"
          type="checkbox"
          checked={makeBroadcast}
          onChange={(e) => setMakeBroadcast(e.target.checked)}
        />
        <span className="admin-title">Рассылка акции</span>
      </label>
      <div className="admin-container">
        <button className="admin-button" type="submit">
          Создать
        </button>
      </div>
    </form>
  )
}

export default AddProductPage
