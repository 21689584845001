import React from 'react'

function CheckIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="32"
      height="32"
      style={{ backgroundColor: 'var(--tg-theme-secondary-bg-color)' }}
      {...props}
    >
      <path
        fill="#5BAF4E"
        d="M21.03 5.72a.75.75 0 0 1 0 1.06l-11.5 11.5a.747.747 0 0 1-1.072-.012l-5.5-5.75a.75.75 0 1 1 1.084-1.036l4.97 5.195L19.97 5.72a.75.75 0 0 1 1.06 0Z"
      ></path>
    </svg>
  )
}

export default CheckIcon
