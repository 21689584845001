import { useEffect } from 'react'
import { useTelegram } from './useTelegram'

export const useBackButton = (handleBackButtonClick) => {
  const { onBackButtonClick, offBackButtonClick, onBackButtonToggle } =
    useTelegram()

  useEffect(() => {
    onBackButtonClick(handleBackButtonClick)
    onBackButtonToggle() // Показать кнопку "Назад"
    return () => {
      offBackButtonClick(handleBackButtonClick)
      onBackButtonToggle() // Скрыть кнопку "Назад"
    }
  }, [])
}
