import React, { useState, useEffect } from 'react'
import './App.css'
import Header from './components/Header/Header'
import { Route, Routes } from 'react-router-dom'
import CategoryList from './components/CategoryList/CategoryList'
import ProductList from './components/ProductList/ProductList'
import ProfilePage from './components/Profile/ProfilePage'
import SVGComponent from './components/Spinner/SVGSpinner'
import ReplenishPage from './components/Replenish/ReplenishPage'
import ProductPage from './components/ProductPage/ProductPage'
import { AppProvider } from './context/AppProvider'
import SupercellIdPage from './components/supercell/SupercellIdPage'
import { useTelegram } from './hooks/useTelegram'
import BackButtonComponent from './components/Button/BackButtonComponent'
import SupercellCodePage from './components/supercell/SupercellCodePage'
import ReviewPage from './components/review/ReviewPage'
import NavBar from './components/Navbar/Navbar'
import OrdersPage from './components/OrdersPage/OrdersPage'
import GiftPage from './components/GiftPage/GiftPage'
import AddProductPage from './components/Admin/AddProductPage'
import ConfettiComponent from './components/GiftPage/Confetti'
import BroadcastPage from './components/Admin/BroadcastPage'

function App() {
  const { tg, user } = useTelegram()

  useEffect(() => {
    const { id, first_name, last_name, username } = tg.initDataUnsafe?.user
    if (id && first_name) {
      console.log(`${process.env.REACT_APP_API_URL}/update-user`)
      fetch(`${process.env.REACT_APP_API_URL}/update-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'any-value',
        },
        body: JSON.stringify({
          id: id.toString(),
          firstName: first_name,
          lastName: last_name || '',
          username: username || '',
        }),
      })
        .then((response) => {
          console.log('Request sent, received response', response)
          return response.json()
        })
        .then((data) => {
          console.log('Success:', data)
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }, [tg])

  useEffect(() => {
    tg.ready()
    const startParam = tg.initDataUnsafe?.start_param
    if (startParam && user) {
      fetch(
        `${process.env.REACT_APP_API_URL}/update-referral-link-id/${user.id}/${startParam}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': 'any-value',
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          console.log('Success:', data)
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }, [user])

  const [loading, setLoading] = useState(true)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false)
    }, 2000)

    return () => clearTimeout(timer)
  }, [])

  if (loading) {
    return <SVGComponent />
  }

  return (
    <AppProvider>
      <div className="App">
        <Routes>
          <Route
            index
            element={
              <div
                style={{
                  backgroundColor: 'var(--tg-theme-bg-color)',
                  color: 'var(--tg-theme-text-color)',
                  paddingTop: '60px',
                }}
              >
                <Header className="header" onSearch={setSearchTerm} />
                <CategoryList style={{ paddingTop: '60px' }} />
                <ProductList searchTerm={searchTerm} />
                <NavBar />
              </div>
            }
          />
          <Route
            path={'/products/:game'}
            element={
              <div
                style={{
                  backgroundColor: 'var(--tg-theme-bg-color)',
                  color: 'var(--tg-theme-text-color)',
                  paddingTop: '50px',
                }}
              >
                <Header className="header" onSearch={setSearchTerm} />
                <ProductList searchTerm={searchTerm} />
                <BackButtonComponent />
                <NavBar />
              </div>
            }
          />
          <Route
            path={'profile'}
            element={
              <>
                <ProfilePage />
                <BackButtonComponent />
                <NavBar />
              </>
            }
          />

          <Route
            path={'admin'}
            element={
              <>
                <AddProductPage />
                <BackButtonComponent />
              </>
            }
          />
          <Route
            path={'broadcast'}
            element={
              <>
                <BroadcastPage />
                <BackButtonComponent />
              </>
            }
          />
          <Route
            path={'promotion'}
            element={
              <>
                {/* <ConfettiComponent /> */}
                <GiftPage />
                <BackButtonComponent />
                <NavBar />
              </>
            }
          />
          <Route
            path={'replenish'}
            element={
              <>
                <ReplenishPage />
                <BackButtonComponent />
              </>
            }
          />
          <Route
            path={'/product/:id'}
            element={
              <>
                <ProductPage />
              </>
            }
          />
          <Route
            path={'/'}
            exact
            elements={<ProductList searchTerm={searchTerm} />}
          />
          <Route
            path={'/supercell'}
            element={
              <>
                <SupercellIdPage />
                <BackButtonComponent />
              </>
            }
          />
          <Route
            path={'/leave-review'}
            element={
              <>
                <ReviewPage />
                <BackButtonComponent />
              </>
            }
          />
          <Route
            path={'/supercellcode'}
            element={
              <>
                <SupercellCodePage />
                <BackButtonComponent />
              </>
            }
          />

          <Route
            path={'/orders'}
            element={
              <>
                <OrdersPage />
                <BackButtonComponent />
                <NavBar />
              </>
            }
          />
        </Routes>
      </div>
    </AppProvider>
  )
}

export default App
