import { useNavigate, useLocation } from 'react-router-dom'
import React, { useState, useEffect, useRef } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useTelegram } from '../../hooks/useTelegram'
import LoadingSpinner from '../Spinner/LoadingSpinner'
import CodeInput from './CodeInput'

const SupercellCodePage = () => {
  const location = useLocation()
  const email = location.state.email
  const code = location.state.code
  const productId = location.state.productId
  const [supercellId, setSupercellId] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useTelegram()
  const navigate = useNavigate()
  const tg = window.Telegram.WebApp

  const rootRef = useRef(null)

  useEffect(() => {
    const updateViewportHeight = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    updateViewportHeight()

    window.addEventListener('resize', updateViewportHeight)

    const handleFocus = () => {
      if (rootRef.current) {
        rootRef.current.style.height = `calc(var(--vh, 1vh) * 100)`
      }

      const handleScroll = () => {
        if (rootRef.current) {
          rootRef.current.scrollTop = window.scrollY
          window.scrollTo(0, 0)
        }
      }

      window.addEventListener('scroll', handleScroll, true)

      return () => {
        window.removeEventListener('scroll', handleScroll, true)
      }
    }

    document.addEventListener('focusin', handleFocus)
    document.addEventListener('focusout', handleFocus)

    return () => {
      window.removeEventListener('resize', updateViewportHeight)
      document.removeEventListener('focusin', handleFocus)
      document.removeEventListener('focusout', handleFocus)
    }
  }, [])

  const handleInputChange = (event) => {
    setSupercellId(event.target.value)
  }

  const handleContinue = async (inputCode) => {
    setIsLoading(true)
    try {
      console.log(
        JSON.stringify({
          userId: user.id.toString(),
          productId: productId,
          supercellEmail: email,
          supercellCode: inputCode, // использовать inputCode вместо supercellId
        })
      )
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/purchase`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': 'any-value',
          },
          body: JSON.stringify({
            userId: user.id.toString(),
            productId: productId,
            supercellEmail: email,
            supercellCode: inputCode, // использовать inputCode вместо supercellId
          }),
        }
      )

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }

      const data = await response.json()
      console.log(data)
      navigate('/')
    } catch (error) {
      console.error('Error making purchase:', error)
    }
    setIsLoading(false)
  }

  useEffect(() => {
    return () => {}
  }, [handleContinue])

  return (
    <div className="h-root" ref={rootRef}>
      <img
        src="/supercell-id-code.webp"
        alt="Supercell ID Code"
        style={{ width: '100%', padding: '10px' }}
      />{' '}
      {/* Добавление стиля */}
      <p
        style={{
          width: '100%',
          padding: '10px',
          color: 'var(--tg-theme-hint-color)',
          fontStyle: 'italic',
          margin: '0',
          fontSize: '14px',
        }}
      >
        Введите код верификации, отправленный Вам на почту.
      </p>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CodeInput length={6} loading={isLoading} onComplete={handleContinue} />
      </div>
    </div>
  )
}

export default SupercellCodePage
