import React from 'react'

function PlusIcon({ active }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16"
      height="16"
      fill="#ffffff"
      style={{ backgroundColor: 'transparent' }}
    >
      <defs>
        <linearGradient id="plusGradient" gradientTransform="rotate(45)">
          <stop offset="0%" stopColor="#fb26b9" />
          <stop offset="100%" stopColor="#7c04fd" />
        </linearGradient>
      </defs>
      <path
        d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0M8.5 4.5a.5.5 0 0 0-1 0v3h-3a.5.5 0 0 0 0 1h3v3a.5.5 0 0 0 1 0v-3h3a.5.5 0 0 0 0-1h-3z"
        // d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"
      />
    </svg>
  )
}

export default PlusIcon
