import React from 'react'

function RubleIcon({ active }) {
  return (
    <svg
      fill="none"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      style={{ backgroundColor: 'transparent' }}
    >
      <defs>
        <linearGradient id="plusGradient" gradientTransform="rotate(45)">
          <stop offset="0%" stopColor="#fb26b9" />
          <stop offset="100%" stopColor="#7c04fd" />
        </linearGradient>
      </defs>
      <path
        fill-rule="evenodd"
        // fill={active ? 'url(#plusGradient)' : 'var(--tg-theme-text-color)'}
        stroke="var(--tg-theme-text-color)"
        d="M15,2 C18.3137085,2 21,4.6862915 21,8 C21,11.2383969 18.4344251,13.8775718 15.2249383,13.9958615 L15,14 L10,14 L10,16 L14,16 C14.5522847,16 15,16.4477153 15,17 C15,17.5522847 14.5522847,18 14,18 L10,18 L10,21 C10,21.5522847 9.55228475,22 9,22 C8.44771525,22 8,21.5522847 8,21 L8,18 L4,18 C3.44771525,18 3,17.5522847 3,17 C3,16.4477153 3.44771525,16 4,16 L8,16 L8,14 L4,14 C3.44771525,14 3,13.5522847 3,13 C3,12.4871642 3.38604019,12.0644928 3.88337887,12.0067277 L4,12 L8,12 L8,3 C8,2.44771525 8.44771525,2 9,2 L15,2 Z M15,4 L10,4 L10,12 L15,12 C17.209139,12 19,10.209139 19,8 C19,5.85780461 17.3160315,4.10892112 15.1996403,4.00489531 L15,4 Z"
      />
    </svg>
  )
}

export default RubleIcon
