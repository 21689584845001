import React, { useState } from 'react'
import axios from 'axios'
import './AddProductPage.css'

const BroadcastPage = () => {
  const [message, setMessage] = useState('')
  const [redirectUrl, setRedirectUrl] = useState(
    'https://t.me/paychara_bot/paychara'
  )
  const [imageUrl, setImageUrl] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()

    // Проверка на пустые поля
    if (!message || !redirectUrl || !imageUrl) {
      alert('Пожалуйста, заполните все поля')
      return
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/broadcast`,
        {
          message,
          redirectUrl,
          imageUrl,
        }
      )

      if (response.status !== 200) {
        throw new Error('Ошибка при отправке сообщения')
      }

      alert('Сообщение успешно отправлено')
    } catch (error) {
      console.error(error)
      alert('Ошибка при отправке сообщения')
    }
  }

  return (
    <form onSubmit={handleSubmit} className="add-product-form">
      <div className="admin-container">
        <label className="admin-title" htmlFor="message">
          Сообщение
        </label>
        <input
          className="admin-input"
          id="message"
          type="text"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Сообщение"
          required
        />
      </div>
      <div className="admin-container">
        <label className="admin-title" htmlFor="redirectUrl">
          URL для перенаправления
        </label>
        <input
          className="admin-input"
          id="redirectUrl"
          type="text"
          value={redirectUrl}
          onChange={(e) => setRedirectUrl(e.target.value)}
          placeholder="URL для перенаправления"
          required
        />
      </div>
      <div className="admin-container">
        <label className="admin-title" htmlFor="imageUrl">
          URL изображения
        </label>
        <input
          className="admin-input"
          id="imageUrl"
          type="text"
          value={imageUrl}
          onChange={(e) => setImageUrl(e.target.value)}
          placeholder="URL изображения"
          required
        />
      </div>
      <div className="admin-container">
        <button className="admin-button" type="submit">
          Отправить
        </button>
      </div>
    </form>
  )
}

export default BroadcastPage
