import React, { useState } from 'react'
import './CategoryList.css'
import CategoryItem from '../CategoryItem/CategoryItem'
// import { useTelegram } from '../../hooks/useTelegram'
import { useCallback, useEffect } from 'react'

const categories = [
  {
    id: '1',
    img: '/brawl2.webp',
    title: 'Brawl Stars',
  },
  {
    id: '2',
    img: '/royale.webp',
    title: 'Clash Royale',
  },
  {
    id: '3',
    img: '/clash-of-clans.webp',
    title: 'Clash of Clans',
  },
  {
    id: '4',
    img: '/roblox.webp',
    title: 'Roblox',
  },
]

const getTotalPrice = (items = []) => {
  return items.reduce((acc, item) => {
    return (acc += item.price)
  }, 0)
}

const CategoryList = () => {
  return (
    <div className="container">
      <h1 className="game-title">Игры</h1>
      <div className="d-flex flex-row overflow-auto">
        {categories.map((item) => (
          <CategoryItem category={item} key={item.id} className={'item'} />
        ))}
      </div>
    </div>
  )
}

export default CategoryList
