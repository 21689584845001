import React, { useState, useEffect, useContext } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom'
import LoadingSpinner from '../Spinner/LoadingSpinner.jsx'
import { useTelegram } from '../../hooks/useTelegram.js'
import './SupercellIdPage.css'
import { AppContext } from '../../context/AppProvider.jsx'

const SupercellIdPage = () => {
  const { supercellEmail } = useContext(AppContext)
  const [supercellId, setSupercellId] = useState(supercellEmail || '')
  const [code, setCode] = useState('') // State for the code input
  const [isLoading, setIsLoading] = useState(false)
  const [promoCodeResponse, setPromoCodeResponse] = useState('')
  const [emailError, setEmailError] = useState('')
  const [focus, setFocus] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const productId = location.state?.productId
  const tg = window.Telegram.WebApp
  const [saveEmail, setSaveEmail] = useState(false)
  tg.MainButton.show()

  const rootRef = React.useRef(null)

  const handleSaveEmailChange = (event) => {
    setSaveEmail(event.target.checked)
  }

  useEffect(() => {
    const updateViewportHeight = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    updateViewportHeight()

    window.addEventListener('resize', updateViewportHeight)

    const handleFocus = () => {
      if (rootRef.current) {
        rootRef.current.style.height = `calc(var(--vh, 1vh) * 100)`
      }

      // Scroll event listener
      const handleScroll = () => {
        if (rootRef.current) {
          rootRef.current.scrollTop = window.scrollY
          window.scrollTo(0, 0)
        }
      }

      window.addEventListener('scroll', handleScroll, true)

      return () => {
        window.removeEventListener('scroll', handleScroll, true)
      }
    }

    document.addEventListener('focusin', handleFocus)
    document.addEventListener('focusout', handleFocus)

    return () => {
      window.removeEventListener('resize', updateViewportHeight)
      document.removeEventListener('focusin', handleFocus)
      document.removeEventListener('focusout', handleFocus)
    }
  }, [])

  const handleContinue = async () => {
    tg.MainButton.showProgress()
    setIsLoading(true)
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/logintosupercell`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': 'any-value',
          },
          body: JSON.stringify({
            email: supercellId,
            saveEmail: saveEmail,
            userId: tg.initDataUnsafe?.user.id,
          }),
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()
      console.log(data)
      navigate('/supercellcode', {
        state: { email: supercellId, code: code, productId: productId },
      })
    } catch (error) {
      console.error('Error logging in:', error)
      alert('Не удалось отправить код. Пожалуйста, попробуйте еще раз.') // Alert in case of error
    }
    setIsLoading(false)
    tg.MainButton.hideProgress()
  }

  useEffect(() => {
    tg.MainButton.setParams({
      color: '#7c04fd',
    })
    tg.MainButton.setText('Продолжить').show().enable().onClick(handleContinue)

    return () => {
      tg.MainButton.offClick(handleContinue)
    }
  }, [handleContinue])

  const handleSupercellIdChange = (event) => {
    const email = event.target.value
    setSupercellId(email)

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    if (!emailRegex.test(email)) {
      setEmailError('Введите действительный адрес электронной почты')
    } else {
      setEmailError('')
    }
  }

  return (
    <div className="h-root" ref={rootRef}>
      <img
        src="/supercell-banner.webp"
        alt="Supercell ID"
        style={{ width: '100%' }}
      />{' '}
      <div style={{ padding: '5px' }}>
        <Form.Group controlId="formSupercellId" style={{ marginTop: '10px' }}>
          <div className="supercell-input-group">
            <input
              required
              type="email"
              name="text"
              autocomplete="off"
              className="supercell-input"
              value={supercellId}
              onChange={handleSupercellIdChange}
              isInvalid={!!emailError}
              onBlur={() => setFocus(false)}
              onFocus={() => setFocus(true)}
            />
            <label
              className={`supercell-user-label ${
                supercellId && !focus ? 'hidden' : ''
              }`}
            >
              почта Supercell ID
            </label>
          </div>
          <div
            style={{
              marginTop: '10px',
              marginLeft: '10px',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              className="checkbox-wrapper-5"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div class="check">
                <input
                  type="checkbox"
                  id="check-5"
                  checked={saveEmail}
                  onChange={handleSaveEmailChange}
                  style={{
                    alignItems: 'center',
                  }}
                />
                <label for="check-5"></label>
              </div>
            </div>
            <div
              style={{
                marginLeft: '10px',
                fontSize: '16px',
                alignItems: 'center',
                color: saveEmail ? '#7c04fd' : 'gray',
              }}
            >
              Запомнить Email
            </div>
          </div>
          {/* <Form.Control.Feedback
            type="invalid"
            style={{ display: 'block', width: '100%', marginLeft: '10px' }}
          >
            {emailError || ''}
          </Form.Control.Feedback> */}
        </Form.Group>
        <div style={{ margin: '10px', fontSize: '10px', color: 'gray' }}>
          Нажимая кнопку продолжить вы соглашаетесь с условиями{' '}
          <a
            href="https://telegra.ph/Agentskij-dogovor-publichnoj-oferty-na-sovershenie-sdelki-okazanii-uslugi-po-porucheniyu-principala-komitenta-09-11"
            target="_blank"
            rel="noopener noreferrer"
          >
            договора
          </a>
        </div>
      </div>
    </div>
  )
}

export default SupercellIdPage
