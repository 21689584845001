import React, { useState, useEffect } from 'react'
import { Button, Form } from 'react-bootstrap'
import './ReplenishPage.css'
import { useLocation } from 'react-router'
import { useTelegram } from '../../hooks/useTelegram'
import RubleCircleIcon from '../../icons/RubleCircleIcon'
import { useNavigate } from 'react-router-dom'

let isRedirecting = false

const ReplenishPage = () => {
  const location = useLocation()
  const [amount, setAmount] = useState(location.state?.requiredAmount || 0)
  const { user } = useTelegram()
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const tg = window.Telegram.WebApp

  tg.MainButton.show()

  const userId = user.id
  console.log('userid')
  console.log(userId)

  const rootRef = React.useRef(null)

  useEffect(() => {
    const updateViewportHeight = () => {
      const vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
    }

    updateViewportHeight()

    window.addEventListener('resize', updateViewportHeight)

    const handleFocus = () => {
      if (rootRef.current) {
        rootRef.current.style.height = `calc(var(--vh, 1vh) * 100)`
      }

      // Scroll event listener
      const handleScroll = () => {
        if (rootRef.current) {
          rootRef.current.scrollTop = window.scrollY
          window.scrollTo(0, 0)
        }
      }

      window.addEventListener('scroll', handleScroll, true)

      return () => {
        window.removeEventListener('scroll', handleScroll, true)
      }
    }

    document.addEventListener('focusin', handleFocus)
    document.addEventListener('focusout', handleFocus)

    return () => {
      window.removeEventListener('resize', updateViewportHeight)
      document.removeEventListener('focusin', handleFocus)
      document.removeEventListener('focusout', handleFocus)
    }
  }, [])

  useEffect(() => {
    setAmount(location.state?.requiredAmount || 0)
  }, [location.state?.requiredAmount])

  const addAmount = (value) => {
    setAmount(amount + value)
  }

  //WEBPAY
  const handleReplenish = async () => {
    tg.MainButton.showProgress()
    setIsLoading(true)
    try {
      console.log(JSON.stringify({ price: amount, userId }))
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/create-webpay-payment`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': 'any-value',
          },
          body: JSON.stringify({ price: amount, userId }),
        }
      )

      if (!response.ok) {
        throw new Error('Network response was not ok')
      }

      const data = await response.json()

      // Перенаправляем пользователя на URL подтверждения платежа
      isRedirecting = true
      window.location.href = data.redirectUrl
      // tg.openTelegramLink(data.confirmationUrl)
    } catch (error) {
      console.error('Ошибка пополнения баланса:', error)
    } finally {
      setIsLoading(false)
      tg.MainButton.hideProgress()
      tg.MainButton.hide()
    }
  }

  useEffect(() => {
    if (!isRedirecting && amount > 0) {
      tg.MainButton.setParams({
        color: '#7c04fd',
      })
      tg.MainButton.setText('Пополнить')
        .show()
        .enable()
        .onClick(handleReplenish)
    } else {
      tg.MainButton.hide()
    }
    return () => {
      tg.MainButton.offClick(handleReplenish)
    }
  }, [handleReplenish, amount])

  //YOOKASSA

  // const handleReplenish = async () => {
  //   tg.MainButton.showProgress()
  //   setIsLoading(true)
  //   try {
  //     console.log(JSON.stringify({ userId, amount }))
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/create-payment`,
  //       {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           'ngrok-skip-browser-warning': 'any-value',
  //         },
  //         body: JSON.stringify({ userId, amount }),
  //       }
  //     )

  //     if (!response.ok) {
  //       throw new Error('Network response was not ok')
  //     }

  //     const data = await response.json()

  //     // Перенаправляем пользователя на URL подтверждения платежа
  //     window.location.href = data.confirmationUrl
  //     // tg.openTelegramLink(data.confirmationUrl)
  //   } catch (error) {
  //     console.error('Ошибка пополнения баланса:', error)
  //   } finally {
  //     setIsLoading(false)
  //     tg.MainButton.hideProgress()
  //   }
  // }

  return (
    <div className="replenish-page">
      <h1>Пополнение</h1>
      <Form>
        <Form.Group>
          <input
            className="mr-2 flex-grow-1 input-group__input"
            style={{
              textTransform: 'uppercase',
              backgroundImage: amount
                ? 'linear-gradient(var(--tg-theme-bg-color), var(--tg-theme-bg-color)), linear-gradient(120deg, #f09 0%, #0ff 50%, #9f0 100%)'
                : 'linear-gradient(var(--tg-theme-bg-color), var(--tg-theme-bg-color)), linear-gradient(120deg, #C7210A 0%, #FEA534 50%, #FF8A34 100%)',
            }}
            type="number"
            min="0"
            max="9999999"
            id="myInput"
            inputMode="numeric"
            value={amount}
            onChange={(e) => {
              let value = e.target.value
              while (value.length > 1 && value.startsWith('0')) {
                value = value.substring(1)
              }
              if (value.length > 7) {
                value = value.slice(0, 7)
              }
              setAmount(parseInt(value))
            }}
            placeholder="ВВЕДИТЕ СУММУ"
          />
        </Form.Group>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '10px',
            borderRadius: '20px',
            color: 'var(--tg-theme-text-color',
          }}
        >
          {[50, 100, 150, 500, 1000].map((value) => (
            <Button
              variant="primary"
              onClick={() => addAmount(value)}
              key={value}
              style={{
                backgroundColor: 'var(--tg-theme-secondary-bg-color)',
                color: 'var(--tg-theme-text-color)',
                border: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '20px',
              }}
            >
              {value}{' '}
              <RubleCircleIcon
                style={
                  {
                    // backgroundColor: 'var(--tg-theme-text-color)',
                  }
                }
              />
            </Button>
          ))}
        </div>
      </Form>
    </div>
  )
}

export default ReplenishPage
