import React from 'react'

function ClockIcon(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="34"
      height="34"
      {...props}
    >
      <path
        fill="#FFB840"
        stroke="#FFB840"
        strokeWidth="0.7"
        d="M12.5 7.25a.75.75 0 0 0-1.5 0v5.5c0 .27.144.518.378.651l3.5 2a.75.75 0 0 0 .744-1.302L12.5 12.315V7.25Z"
      ></path>
      <path
        fill="#FFB840"
        stroke="#FFB840"
        strokeWidth="0.7"
        d="M12 1c6.075 0 11 4.925 11 11s-4.925 11-11 11S1 18.075 1 12 5.925 1 12 1ZM2.5 12a9.5 9.5 0 0 0 9.5 9.5 9.5 9.5 0 0 0 9.5-9.5A9.5 9.5 0 0 0 12 2.5 9.5 9.5 0 0 0 2.5 12Z"
      ></path>
    </svg>
  )
}

export default ClockIcon
