import React, { useEffect, useState, useRef } from 'react'
import { ButtonGroup, Button } from 'react-bootstrap'
import { useTelegram } from '../../hooks/useTelegram'
import SVGSpinner from '../Spinner/SVGSpinner'
import { useNavigate } from 'react-router-dom'
import ReviewIcon from '../../icons/ReviewIcon'
import CheckIcon2 from '../../icons/CheckIcon2'
import ClockIcon from '../../icons/ClockIcon'
import InvalidIcon from '../../icons/InvalidIcon'
import CancelIcon from '../../icons/CancelIcon'
import LoadingSpinner from '../Spinner/LoadingSpinner'
import './OrdersPage.css'
import ExclamationIcon from '../../icons/ExclamationIcon'
import WarningIcon from '../../icons/WarningIcon'
import { Block } from '@material-ui/icons'

const OrdersPage = () => {
  const [activeKey, setActiveKey] = useState('orders')
  const [orders, setOrders] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [orderRange, setOrderRange] = useState({ start: 0, end: 50 })
  const { user, tg } = useTelegram()
  const [loading, setLoading] = useState(false)
  const [transactions, setTransactions] = useState([])
  const [hasMoreTransactions, setHasMoreTransactions] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [transactionRange, setTransactionRange] = useState({
    start: 0,
    end: 50,
  })
  const loadMoreButtonRef = useRef(null)
  const navigate = useNavigate()
  tg.MainButton.hide()

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (activeKey === 'orders') {
            loadMoreOrders()
          } else if (activeKey === 'transactions') {
            loadMoreTransactions()
          }
        }
      },
      { threshold: 1.0 }
    )

    if (loadMoreButtonRef.current) {
      observer.observe(loadMoreButtonRef.current)
    }

    return () => {
      if (loadMoreButtonRef.current) {
        observer.unobserve(loadMoreButtonRef.current)
      }
    }
  }, [activeKey, orders, transactions, hasMore, hasMoreTransactions])

  useEffect(() => {
    setOrders([])
    setTransactions([])
  }, [activeKey])

  useEffect(() => {
    if (activeKey === 'orders') {
      setLoading(true)
      setIsFetching(true)
      fetch(
        `${process.env.REACT_APP_API_URL}/purchases/${user.id}/${orderRange.start}-${orderRange.end}`,
        {
          headers: {
            'ngrok-skip-browser-warning': 'any-value',
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          if (data && data.purchases) {
            setOrders((prevOrders) => {
              const newOrders = data.purchases.filter(
                (purchase) =>
                  !prevOrders.some((order) => order.id === purchase.id)
              )
              return [...prevOrders, ...newOrders]
            })

            setHasMore(data.hasMore)
          }
          setLoading(false)
          setIsFetching(false)
        })
        .catch((error) => {
          console.error('Error:', error)
          setLoading(false)
          setIsFetching(false)
        })
    }
  }, [activeKey, orderRange])

  useEffect(() => {
    if (activeKey === 'transactions') {
      setLoading(true)
      setIsFetching(true)
      fetch(
        `${process.env.REACT_APP_API_URL}/user-activities/${user.id}/${transactionRange.start}-${transactionRange.end}`,
        {
          headers: {
            'ngrok-skip-browser-warning': 'any-value',
          },
        }
      )
        .then((response) => response.json())
        .then((data) => {
          setTransactions((prevTransactions) => {
            const newTransactions = data.activities.filter(
              (activity) =>
                !prevTransactions.some((trans) => trans.id === activity.id)
            )
            return [...prevTransactions, ...newTransactions]
          })
          setHasMoreTransactions(data.hasMore)
          setLoading(false) // Установите loading в false после загрузки данных
          setIsFetching(false)
        })
        .catch((error) => {
          console.error('Error:', error)
          setLoading(false) // Установите loading в false, даже если произошла ошибка
          setIsFetching(true)
        })
    }
  }, [activeKey, transactionRange])

  const handleLeaveReview = (productId, userId, purchaseId) => {
    navigate(`/leave-review`, { state: { productId, userId, purchaseId } })
  }

  const loadMoreOrders = () => {
    setOrderRange((prevRange) => ({
      start: prevRange.start + 1,
      end: prevRange.end + 20,
    }))
  }

  const loadMoreTransactions = () => {
    setTransactionRange((prevRange) => ({
      start: prevRange.start + 1,
      end: prevRange.end + 20,
    }))
  }

  return (
    <div
      style={{
        paddingBottom: '60px',
        zIndex: '2',
        overflow: 'hidden',
        borderRadius: 0,
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 1000,
          padding: '2px 0px',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <Button
          variant="secondary"
          onClick={() => setActiveKey('orders')}
          active={activeKey === 'orders'}
          className={`transition-btn left`}
          style={{
            color:
              activeKey === 'orders'
                ? window.Telegram.WebApp.colorScheme === 'dark'
                  ? '#FBEFFF'
                  : '#7c04fd'
                : 'var(--tg-theme-text-color)',
            opacity: activeKey === 'orders' ? '1' : '0.5',
            backgroundColor: 'transparent',
            border: 'none',
            width: '50%',
            color: 'var(--tg-theme-text-color)',
          }}
        >
          Заказы
        </Button>
        <Button
          variant="secondary"
          onClick={() => setActiveKey('transactions')}
          active={activeKey === 'transactions'}
          className={`transition-btn right`}
          style={{
            color:
              activeKey === 'transactions'
                ? window.Telegram.WebApp.colorScheme === 'dark'
                  ? '#FBEFFF'
                  : '#7c04fd'
                : 'var(--tg-theme-text-color)',
            opacity: activeKey === 'transactions' ? '1' : '0.5',
            backgroundColor: 'transparent',
            border: 'none',
            width: '50%',
            color: 'var(--tg-theme-text-color)',
          }}
        >
          Транзакции
        </Button>
      </div>
      <div style={{ marginTop: '52px' }}>
        {activeKey === 'orders' && (
          <>
            {orders.map((order, index) => {
              const orderDate = new Date(order.createdAt._seconds * 1000)
              const today = new Date()
              const yesterday = new Date(today)
              yesterday.setDate(yesterday.getDate() - 1)

              let formattedDate
              if (
                orderDate.getDate() === today.getDate() &&
                orderDate.getMonth() === today.getMonth() &&
                orderDate.getFullYear() === today.getFullYear()
              ) {
                formattedDate = 'Сегодня'
              } else if (
                orderDate.getDate() === yesterday.getDate() &&
                orderDate.getMonth() === yesterday.getMonth() &&
                orderDate.getFullYear() === yesterday.getFullYear()
              ) {
                formattedDate = 'Вчера'
              } else {
                formattedDate = orderDate.toLocaleDateString('ru-RU', {
                  day: '2-digit',
                  month: 'long',
                })
              }

              const formattedTime = orderDate.toLocaleTimeString('ru-RU', {
                hour: '2-digit',
                minute: '2-digit',
              })

              let icon
              let backgroundColor = 'transparent'
              let divColor = 'rgba(154,154,154,0.2)'
              switch (order.status) {
                case 'Completed':
                  icon = (
                    <CheckIcon2 style={{ backgroundColor: 'transparent' }} />
                  )
                  break
                case 'In progress':
                  icon = (
                    <ClockIcon style={{ backgroundColor: 'transparent' }} />
                  )
                  break
                case 'Invalid':
                  icon = (
                    <InvalidIcon style={{ backgroundColor: 'transparent' }} />
                  )
                  break
                case 'Canceled':
                  icon = (
                    <CancelIcon style={{ backgroundColor: 'transparent' }} />
                  )
                  break
                default:
                  icon = null
                  divColor = 'transparent'
              }

              return (
                <div>
                  <div
                    key={order.id}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      margin: '5px 5px 5px 20px',
                      width: 'calc(100% - 20px)',
                      borderRadius: '40px',
                      backgroundColor: 'transparent',
                    }}
                  >
                    <div
                      style={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor,
                      }}
                    >
                      {icon}
                    </div>
                    <div
                      className="d-flex align-items-center justify-content-between w-100"
                      style={{ backgroundColor, marginLeft: '20px' }}
                    >
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          flexDirection: 'column',
                          backgroundColor,
                        }}
                      >
                        <div
                          style={{
                            fontSize: '1.2rem',
                            fontWeight: 'bold',
                            backgroundColor,
                          }}
                        >
                          {order.productName}
                        </div>
                        <div
                          style={{
                            fontSize: '0.9rem',
                            backgroundColor,
                            color: 'gray',
                            display: 'flex',
                            flexDirection: 'row',
                          }}
                        >
                          <div>{order.finalPrice} ₽ </div> &nbsp;{'—'}&nbsp;
                          {formattedDate}, {formattedTime}
                        </div>
                      </div>
                      <div
                        style={{
                          width: '60px',
                          alignItems: 'center',
                          display: 'flex',
                          justifyContent: 'center',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <Button
                          style={{
                            position: 'relative',
                            backgroundColor: 'transparent',
                            border: 'none',
                            alignItems: 'center',
                            zIndex: '0',
                            display:
                              !order.reviewExists &&
                              order.status === 'Completed'
                                ? 'flex'
                                : 'none',
                          }}
                          onClick={() =>
                            handleLeaveReview(
                              order.productId,
                              order.userId,
                              order.purchaseId
                            )
                          }
                        >
                          <ReviewIcon
                            style={{
                              backgroundColor: 'transparent',
                              opacity: '0.7',
                            }}
                          />
                        </Button>
                      </div>
                    </div>
                  </div>
                  {index < orders.length - 1 && (
                    <hr
                      style={{
                        borderColor: 'gray',
                        border: '1px solid gray',
                        margin: '0 10px ',
                      }}
                    />
                  )}
                </div>
              )
            })}
            {/* {orders.length === 0 && (
              <div
                style={{
                  display: activeKey === 'orders' && loading ? 'none' : 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '80vh',
                  padding: '0 10px',
                }}
              >
                <p
                  style={{
                    display:
                      activeKey === 'orders' && loading ? 'none' : 'flex',
                  }}
                >
                  Список заказов пуст 🥺
                </p>
              </div>
            )} */}
            {hasMore && (
              <div
                ref={loadMoreButtonRef}
                style={{
                  position: 'relative',
                  backgroundColor: 'transparent',
                  zIndex: '0',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '5px 0',
                }}
              >
                <button
                  style={{
                    position: 'relative',
                    backgroundColor: 'transparent',
                    zIndex: '0',
                  }}
                  onClick={loadMoreOrders}
                >
                  {loading ? <LoadingSpinner /> : 'Показать еще'}
                </button>
              </div>
            )}
          </>
        )}

        {activeKey === 'transactions' && (
          <>
            {transactions.map((transaction, index) => {
              let status = ''
              let color = ''
              let sign = ''

              if (transaction.type === 'transaction') {
                if (transaction.status === 'promo-code') {
                  status = 'Промокод'
                } else if (transaction.status === 'referral') {
                  status = 'Реферальный бонус'
                } else {
                  status = 'Пополнение'
                }
                color = '#2FB382'
                sign = '+'
              } else {
                status = 'Списание'
                color = '#FA2A64'
                sign = '-'
              }
              const transactionDate = new Date(
                transaction.createdAt._seconds * 1000
              )
              const today = new Date()
              const yesterday = new Date(today)
              yesterday.setDate(yesterday.getDate() - 1)

              let formattedDate
              if (
                transactionDate.getDate() === today.getDate() &&
                transactionDate.getMonth() === today.getMonth() &&
                transactionDate.getFullYear() === today.getFullYear()
              ) {
                formattedDate = 'Сегодня'
              } else if (
                transactionDate.getDate() === yesterday.getDate() &&
                transactionDate.getMonth() === yesterday.getMonth() &&
                transactionDate.getFullYear() === yesterday.getFullYear()
              ) {
                formattedDate = 'Вчера'
              } else {
                formattedDate = transactionDate.toLocaleDateString('ru-RU', {
                  day: '2-digit',
                  month: 'long',
                })
              }

              const formattedTime = transactionDate.toLocaleTimeString(
                'ru-RU',
                {
                  hour: '2-digit',
                  minute: '2-digit',
                }
              )

              return (
                <div
                  key={transaction.id}
                  style={{
                    margin: '0px 10px',
                    width: 'calc(100% - 20px)',
                    backgroundColor: 'transparent',
                  }}
                >
                  <div style={{ padding: '0 10px' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        backgroundColor: 'transparent',
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: 'transparent',
                          fontSize: '1.2rem',
                          fontWeight: '500',
                        }}
                      >
                        {status}
                      </div>
                      <div
                        style={{
                          backgroundColor: 'transparent',
                          fontSize: '0.9rem',
                          opacity: '0.9',
                          color,
                        }}
                      >
                        {sign}
                        {transaction.value} ₽
                      </div>
                    </div>
                    <div
                      style={{
                        backgroundColor: 'transparent',
                        color: 'gray',
                        fontSize: '0.9rem',
                        opacity: '0.9',
                      }}
                    >
                      {formattedDate}, {formattedTime}
                    </div>
                    {index < transactions.length - 1 && (
                      <hr
                        style={{
                          color: 'gray',
                          border: '1px solid gray',
                          margin: '5px 0',
                        }}
                      />
                    )}
                  </div>
                </div>
              )
            })}
            {/* {transactions.length === 0 && (
              <div
                style={{
                  display:
                    activeKey === 'transactions' && loading ? 'none' : 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '80vh',
                  padding: '0 10px',
                }}
              >
                <p
                  style={{
                    display:
                      activeKey === 'orders' && loading ? 'none' : 'flex',
                  }}
                >
                  Список транзакций пуст 🥺
                </p>
              </div>
            )} */}
            {hasMoreTransactions && (
              <div
                style={{
                  position: 'relative',
                  backgroundColor: 'transparent',
                  zIndex: '0',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  margin: '5px 0',
                }}
              >
                <button
                  style={{
                    position: 'relative',
                    backgroundColor: 'transparent',
                    zIndex: '0',
                  }}
                  onClick={loadMoreTransactions}
                >
                  {loading ? <LoadingSpinner /> : 'Показать еще'}
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default OrdersPage
