import React from 'react'

function ProfileIcon({ active }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="36"
      height="36"
      style={{ backgroundColor: 'transparent' }}
    >
      <defs>
        <radialGradient id="homeGradient" gradientTransform="rotate(45)">
          <stop offset="0%" stopColor="#fb26b9" />
          <stop offset="100%" stopColor="#7c04fd" />
        </radialGradient>
      </defs>
      <path
        fill={
          active
            ? window.Telegram.WebApp.colorScheme === 'dark'
              ? 'white'
              : '#7c04fd'
            : 'var(--tg-theme-text-color)'
        }
        opacity={active ? '1' : '0.5'}
        d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0m4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4m-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10s-3.516.68-4.168 1.332c-.678.678-.83 1.418-.832 1.664z"
      ></path>
    </svg>
  )
}

export default ProfileIcon
