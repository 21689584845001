import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import HomeIcon from '../../icons/HomeIcon'
import HistoryIcon from '../../icons/HistoryIcon'
import ProfileIcon from '../../icons/ProfileIcon'
import StackIcon from '../../icons/StackIcon'
import GiftIcon from '../../icons/GiftIcon'
import ShopIcon from '../../icons/ShopIcon'
import { useState } from 'react'

export default function NavBar() {
  const navigate = useNavigate()
  const location = useLocation()

  const [activeButton, setActiveButton] = useState(null)

  const handleButtonClick = (path) => {
    handleNavigation(path)
    setActiveButton(path)
  }

  const isActive = (path) => location.pathname === path

  const handleNavigation = (path) => {
    navigate(path)
  }

  return (
    <div
      className="container-navbar"
      style={{
        position: 'fixed',
        bottom: 0,
        width: '100%',
        display: 'flex',
        height: '65px',
        justifyContent: 'space-around',
        backgroundColor: 'var(--tg-theme-bg-color)',
        color: 'var(--tg-theme-text-color)',

        // paddingBottom: 'env(safe-area-inset-bottom)',
      }}
    >
      <div
        style={{
          bottom: 0,
          width: '100%',
          display: 'flex',
          height: '55px',
          justifyContent: 'space-around',
          backgroundColor: 'var(--tg-theme-bg-color)',
          color: 'var(--tg-theme-text-color)',
          backdropFilter: 'blur(100px)',
          marginBottom: '10px',
          width: '100%',
          alignItems: 'center',
          // paddingBottom: 'env(safe-area-inset-bottom)',
        }}
      >
        <button
          onClick={() => handleButtonClick('/')}
          style={{
            backgroundColor: 'transparent',

            borderRadius: '50%',
            height: '45px',
            width: '45px',
          }}
        >
          <ShopIcon
            active={isActive('/')}
            style={{
              backgroundColor:
                activeButton === '/' ? 'var(--tg-theme-bg-color)' : 'none',
            }}
          />
        </button>
        {/* <button
          onClick={() => handleButtonClick('/promotion')}
          style={{
            backgroundColor: 'transparent',

            borderRadius: '50%',
            height: '45px',
            width: '45px',
          }}
        >
          <GiftIcon
            active={isActive('/promotion')}
            style={{
              backgroundColor:
                activeButton === '/promotion'
                  ? 'var(--tg-theme-bg-color)'
                  : 'none',
            }}
          />
        </button> */}
        <button
          onClick={() => handleButtonClick('/orders')}
          style={{
            backgroundColor: 'transparent',
            borderRadius: '50%',
            height: '45px',
            width: '45px',
          }}
        >
          <HistoryIcon
            active={isActive('/orders')}
            style={{
              backgroundColor:
                activeButton === '/orders'
                  ? 'var(--tg-theme-bg-color)'
                  : 'none',
            }}
          />
        </button>
        <button
          onClick={() => handleButtonClick('/profile')}
          style={{
            backgroundColor: 'transparent',
            borderRadius: '50%',
            height: '45px',
            width: '45px',
          }}
        >
          <ProfileIcon
            active={isActive('/profile')}
            style={{
              backgroundColor:
                activeButton === '/profile'
                  ? 'var(--tg-theme-bg-color)'
                  : 'none',
            }}
          />
        </button>
      </div>
    </div>
  )
}
