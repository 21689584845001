import { useState, useEffect } from 'react'
const tg = window.Telegram.WebApp

export function useTelegram() {
  const [user, setUser] = useState(null)

  useEffect(() => {
    if (tg.initDataUnsafe?.user) {
      setUser(tg.initDataUnsafe.user)
    }
    tg.expand()
    tg.enableVerticalSwipes()
  }, [])
  const onClose = () => {
    tg.close()
  }

  const onBackButtonToggle = () => {
    if (tg.BackButton.isVisible) {
      tg.BackButton.hide()
    } else {
      tg.BackButton.show()
    }
  }

  const onBackButtonClick = (callback) => {
    tg.BackButton.onClick(callback)
  }

  const offBackButtonClick = (callback) => {
    tg.BackButton.offClick(callback)
  }

  return {
    onClose,
    onBackButtonToggle,
    onBackButtonClick,
    offBackButtonClick,
    tg,
    user: tg.initDataUnsafe?.user,
    queryId: tg.initDataUnsafe?.query_id,
  }
}
