import React from 'react'
import ConfettiExplosion from 'react-confetti-explosion'

const containerStyle = {
  position: 'absolute', // Контейнер занимает всю доступную площадь
  width: '100%',
  height: '90%', // Высота на весь экран
  backgroundColor: 'transparent',
}

const confettiStyle = {
  position: 'absolute', // Конфетти размещаются поверх всех элементов
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  pointerEvents: 'none', // Отключаем возможность взаимодействия с конфетти
  zIndex: 999, // Высокий z-index для отображения поверх других элементов
  backgroundColor: 'transparent',
}

const bigExplodeProps = {
  force: 0.6,
  duration: 5000,
  particleCount: 200,
  floorHeight: 200,
  floorWidth: 200,
  backgroundColor: 'transparent',
}

function ConfettiComponent() {
  return (
    <div style={containerStyle}>
      <div style={confettiStyle}>
        <ConfettiExplosion {...bigExplodeProps} />
      </div>
    </div>
  )
}

export default ConfettiComponent
