import React, { useContext } from 'react'
import './CategoryItem.css'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../context/AppProvider'

const CategoryItem = ({ category, className, onAdd }) => {
  const navigate = useNavigate()

  const handleClick = async () => {
    const categoryKebabCase = category.title.toLowerCase().replace(/ /g, '-')
    navigate(`products/${categoryKebabCase}`)
  }

  return (
    <div className={'category ' + className} onClick={handleClick}>
      <img src={category.img} alt={category.title} className="category-img" />
      {/* <div className={'category-title'}>{category.title}</div> */}
    </div>
  )
}

export default CategoryItem
