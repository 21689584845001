import React, { useState, useEffect, useContext, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import './ProductPage.css'
import SVGSpinner from '../Spinner/SVGSpinner'
import BackButtonComponent from '../Button/BackButtonComponent'
import { useTelegram } from '../../hooks/useTelegram'
import { useNavigate } from 'react-router'
import LoadingSpinner from '../Spinner/LoadingSpinner'
import { AppContext } from '../../context/AppProvider.jsx'
import ExclamationIcon from '../../icons/ExclamationIcon.jsx'

const ProductPage = () => {
  const { role } = useContext(AppContext)
  const { id } = useParams()
  const [product, setProduct] = useState(null)
  const [reviews, setReviews] = useState([])
  const [hasMore, setHasMore] = useState(false)
  const [reviewRange, setReviewRange] = useState({ start: 0, end: 20 })
  const [isLoading, setIsLoading] = useState(false)
  const { user } = useTelegram()
  const [balance, setBalance] = useState(0)
  const [loadMoreVisible, setLoadMoreVisible] = useState(false)
  const navigate = useNavigate()
  const tg = window.Telegram.WebApp

  const handleBuy = () => {
    console.log(balance)
    if (product.price > balance) {
      navigate('/replenish', {
        state: { requiredAmount: product.price - balance },
      })
    } else {
      navigate('/supercell', { state: { productId: id } })
    }
  }

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/product/${id}`, {
      headers: {
        'ngrok-skip-browser-warning': 'any-value',
      },
    })
      .then((response) => response.json())
      .then((data) => setProduct(data))
      .catch((error) => console.error('Ошибка получения товаров:', error))
  }, [id])

  useEffect(() => {
    tg.MainButton.setParams({
      color: '#7c04fd',
    })
    tg.MainButton.setText('Купить').show().enable().onClick(handleBuy)

    return () => {
      tg.MainButton.offClick(handleBuy)
    }
  }, [handleBuy])

  const loadMoreButtonRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMoreReviews()
        }
      },
      { threshold: 1.0 }
    )

    if (loadMoreButtonRef.current) {
      observer.observe(loadMoreButtonRef.current)
    }

    return () => {
      if (loadMoreButtonRef.current) {
        observer.unobserve(loadMoreButtonRef.current)
      }
    }
  }, [reviews, hasMore])

  useEffect(() => {
    if (user?.id) {
      fetch(`${process.env.REACT_APP_API_URL}/get-user-balance/${user.id}`, {
        headers: {
          'ngrok-skip-browser-warning': 'any-value',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setBalance(data.balance)
        })
        .catch((error) => {
          console.error('Error:', error)
        })
    }
  }, [user])

  console.log('----1')
  console.log(product)
  console.log('----2')
  useEffect(() => {
    const fetchReviews = async (productId, start, end) => {
      setIsLoading(true)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/reviews/${productId}/${start}-${end}`,
          {
            headers: {
              'ngrok-skip-browser-warning': 'any-value',
            },
          }
        )
        console.log(
          `${process.env.REACT_APP_API_URL}/reviews/${productId}/${start}-${end}`
        )
        console.log(response)
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const data = await response.json()
        setReviews((prevReviews) => [...prevReviews, ...data.reviews])
        setHasMore(data.hasMore)
        setIsLoading(false)
      } catch (error) {
        console.error('Ошибка получения отзывов:', error)
        setIsLoading(false)
      }
    }

    fetchReviews(id, reviewRange.start, reviewRange.end)
  }, [id, reviewRange])

  if (!product) {
    return <SVGSpinner />
  }

  const filledStars = '★'.repeat(product.rating)
  const emptyStars = '☆'.repeat(5 - product.rating)

  const loadMoreReviews = () => {
    if (!isLoading && hasMore) {
      // только если не идет загрузка
      setReviewRange((prevRange) => ({
        start: prevRange.end + 1,
        end: prevRange.end + 10,
      }))
    }
  }

  const handleDeleteProduct = () => {
    if (window.confirm('Вы уверены, что хотите удалить этот товар?')) {
      fetch(`${process.env.REACT_APP_API_URL}/product/${id}`, {
        method: 'DELETE',
        headers: {
          'ngrok-skip-browser-warning': 'any-value',
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Ошибка при удалении товара')
          }
          return response.json()
        })
        .then((data) => {
          alert(data.message)
          navigate('/')
        })
        .catch((error) => {
          console.error('Error:', error)
          alert('Ошибка при удалении товара')
        })
    }
  }

  const handleDelete = (id) => {
    if (
      window.confirm(
        `Вы уверены, что хотите удалить этот отзыв?\n${reviews[id].reviewText}`
      )
    ) {
      fetch(`${process.env.REACT_APP_API_URL}/review/${reviews[id].id}`, {
        method: 'DELETE',
        headers: {
          'ngrok-skip-browser-warning': 'any-value',
        },
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Ошибка при удалении отзыва')
          }
          return response.json()
        })
        .then((data) => {
          alert(data.message)
        })
        .catch((error) => {
          console.error('Error:', error)
          alert('Ошибка при удалении отзыва')
        })
    }
  }

  return (
    <div style={{ padding: '10px' }}>
      <BackButtonComponent />
      {role === 'admin' && (
        <Button variant="danger" onClick={handleDeleteProduct}>
          Удалить товар
        </Button>
      )}
      <img src={product.image} alt={product.name} className="product-img" />

      {product.isOnSale && (
        <div className="warning-block">
          <div
            style={{
              width: '36px',
              height: '36px',
              display: 'flex',
              alignItems: 'top',
              backgroundColor: 'transparent',
            }}
          >
            <ExclamationIcon className="exclamation" />
          </div>
          <div className="warning-text" style={{ marginLeft: '10px' }}>
            Перед покупкой убедитесь, что акция активна на вашем аккаунте
            Supercell. Заказ без акции отменяется.
          </div>
        </div>
      )}

      <div
        className="d-flex justify-content-between mt-2"
        style={{
          padding: '0 5px',
          width: '100%',
        }}
      >
        <div className={'product-title bold-font'}>{product.name}</div>
        <div className={'product-price bold-font'}>{product.price} ₽</div>{' '}
      </div>
      <div className="d-flex">
        <div
          className="product-stars d-flex align-items-center"
          style={{
            padding: '0 5px',
            width: '100%',
          }}
        >
          <div>
            <span className="star-icon">★</span>
            <span className="ml-2">
              {' '}
              {(product.rating === 0 ? 5 : product.rating).toFixed(1)}{' '}
            </span>
            <span className="ml-2" style={{ opacity: 0.5 }}>
              ({product.reviewCount} оценок)
            </span>
          </div>
        </div>
      </div>
      {reviews.length > 0 && (
        <>
          <h1 className="header-review mt-3">Отзывы</h1>

          <div
            className="reviews"
            style={{
              backgroundColor: 'var(--tg-theme-bg-color)',
            }}
          >
            {reviews.map((review, index) => {
              const reviewDate = new Date(review.createdAt._seconds * 1000)
              const today = new Date()
              const yesterday = new Date(today)
              yesterday.setDate(yesterday.getDate() - 1)

              let formattedDate
              if (
                reviewDate.getDate() === today.getDate() &&
                reviewDate.getMonth() === today.getMonth() &&
                reviewDate.getFullYear() === today.getFullYear()
              ) {
                formattedDate = 'Сегодня'
              } else if (
                reviewDate.getDate() === yesterday.getDate() &&
                reviewDate.getMonth() === yesterday.getMonth() &&
                reviewDate.getFullYear() === yesterday.getFullYear()
              ) {
                formattedDate = 'Вчера'
              } else {
                formattedDate = reviewDate.toLocaleDateString('ru-RU', {
                  day: '2-digit',
                  month: 'long',
                })
              }

              const formattedTime = reviewDate.toLocaleTimeString('ru-RU', {
                hour: '2-digit',
                minute: '2-digit',
              })

              return (
                <div
                  key={index}
                  className="card mt-2"
                  style={{
                    padding: '3%',
                    border: 'none',
                    backgroundColor: 'var(--tg-theme-secondary-bg-color)',
                    borderRadius: '15px',
                    marginBottom: '10px',
                  }}
                >
                  <div
                    className="d-flex align-items-center"
                    style={{
                      backgroundColor: 'var(--tg-theme-secondary-bg-color)',
                    }}
                  >
                    {review.avatarUrl ? (
                      <img
                        src={review.avatarUrl}
                        alt={review.name}
                        className="avatar rounded-circle mr-3"
                        style={{
                          backgroundColor: 'var(--tg-theme-secondary-bg-color)',
                          width: '50px',
                          height: '50px',
                        }}
                      />
                    ) : (
                      <div
                        className="avatar-placeholder rounded-circle text-white mr-3 d-flex justify-content-center align-items-center"
                        style={{
                          backgroundColor: 'gray',
                          width: '50px',
                          height: '50px',
                        }}
                      >
                        {review.name.charAt(0).toUpperCase()}
                      </div>
                    )}
                    <div className="ml-3 flex-grow-1">
                      <div
                        className="d-flex align-items-center justify-content-between"
                        style={{
                          backgroundColor: 'var(--tg-theme-secondary-bg-color)',
                        }}
                      >
                        <div className="review-user-name card-title mb-0">
                          {review.name}
                        </div>
                        <div className="review-rating text-warning ml-auto">
                          {'★'.repeat(review.rating)}
                          {'☆'.repeat(5 - review.rating)}
                        </div>
                      </div>
                      <div className="review-date">{`${formattedDate}, ${formattedTime}`}</div>
                    </div>
                  </div>
                  <div className="card-text mt-1">{review.reviewText}</div>
                  <button
                    onClick={() => handleDelete(index)}
                    style={{
                      border: 'none',
                      backgroundColor: '#F54E43',
                      color: 'var(--tg-theme-text-color)',
                      borderRadius: '10px',
                      display:
                        role === 'admin' || role === 'manager'
                          ? 'block'
                          : 'none',
                      marginTop: '10px',
                    }}
                  >
                    удалить
                  </button>
                </div>
              )
            })}
            {hasMore && (
              <button
                ref={loadMoreButtonRef}
                className="has-more mt-2"
                disabled={isLoading}
              >
                {isLoading ? <LoadingSpinner /> : 'Показать еще'}
              </button>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default ProductPage
