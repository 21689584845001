import { useBackButton } from '../../hooks/useBackButton'
import { useNavigate } from 'react-router-dom'

const BackButtonComponent = () => {
  const navigate = useNavigate()

  const handleBackButtonClick = () => {
    navigate(-1)
  }

  useBackButton(handleBackButtonClick)

  return null
}

export default BackButtonComponent
