import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import PlusIcon from '../../icons/PlusIcon'
import './Header.css'
import { useTelegram } from '../../hooks/useTelegram'
import RubleIcon from '../../icons/RubleIcon'

const Header = ({ onSearch }) => {
  const { user } = useTelegram()
  const [balance, setBalance] = useState(0)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (user?.id) {
      fetch(`${process.env.REACT_APP_API_URL}/get-user-balance/${user.id}`, {
        headers: {
          'ngrok-skip-browser-warning': 'any-value',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          setBalance(data.balance)
          setIsLoading(false)
        })
        .catch((error) => {
          console.error('Error:', error)
          setIsLoading(false)
        })
    } else {
      setIsLoading(false)
    }
  }, [user])

  if (isLoading) {
    return null // или <Spinner /> для отображения индикатора загрузки
  }

  return (
    <div className={'header'}>
      <div className="form-group">
        <input
          className="search-input"
          type="text"
          placeholder="Поиск..."
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      <Link
        to="/replenish"
        className="balance gradient-button"
        style={{ textDecoration: 'none' }}
      >
        <div className="balance gradient-button">
          {balance} ₽&nbsp;
          <PlusIcon />
        </div>
      </Link>
    </div>
  )
}

export default Header
