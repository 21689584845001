import React from 'react'
import './ProductItem.css'

const ProductItem = ({ product, className, onAdd }) => {
  return (
    <div className={'product ' + className}>
      <img src={product.image} alt={product.name} className="product-img" />
      <div
        className="d-flex justify-content-between"
        style={{
          padding: '5px 5px 0 5px',
          backgroundColor: 'var(--tg-theme-secondary-bg-color)',
        }}
      >
        <div className={'title large-font bold-font'}>{product.name}</div>
        <div className={'price large-font bold-font'}>
          {product.price} ₽
        </div>{' '}
      </div>
      <div
        className="d-flex justify-content-between"
        style={{
          padding: '0 5px',
          backgroundColor: 'var(--tg-theme-secondary-bg-color)',
        }}
      >
        <div className={'game-name'}>{product.game}</div>
        {product.reviewCount > 0 && (
          <div className={'stars'}>
            <span className="unfilled-stars">★</span>
            <span className="rating">
              {(product.rating === 0 ? 5 : product.rating).toFixed(1)}
            </span>
          </div>
        )}
      </div>
    </div>
  )
}
export default ProductItem
