import { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Form } from 'react-bootstrap'

const ReviewPage = () => {
  const [rating, setRating] = useState(5)
  const [review, setReview] = useState('')
  const [symbols, setSymbols] = useState(0)
  const location = useLocation()
  const navigate = useNavigate()
  const tg = window.Telegram.WebApp

  const { productId, userId, purchaseId } = location.state

  const handleStarClick = (starIndex) => {
    setRating(starIndex)
  }

  const handleReviewChange = (event) => {
    const text = event.target.value
    setReview(text)
    setSymbols(text.length)
  }

  const handleSubmit = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/add-review`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'ngrok-skip-browser-warning': 'any-value',
          },
          body: JSON.stringify({
            userId,
            productId,
            purchaseId,
            rating,
            reviewText: review,
          }),
        }
      )

      console.log(
        JSON.stringify({
          userId,
          productId,
          purchaseId,
          rating,
          reviewText: review,
        })
      )

      if (!response.ok) {
        throw new Error('Ошибка при добавлении отзыва')
      }

      navigate('/')
    } catch (error) {
      console.error('Ошибка при добавлении отзыва:', error)
    }
  }

  tg.MainButton.setParams({
    color: '#7c04fd',
  })
  tg.MainButton.setText('Отправить').show().enable().onClick(handleSubmit)

  useEffect(() => {
    tg.MainButton.setText('Отправить').show().enable().onClick(handleSubmit)

    return () => {
      tg.MainButton.offClick(handleSubmit)
    }
  }, [handleSubmit])

  return (
    <div>
      <div
        style={{
          width: '90%',
          margin: '0 5%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'rgba(125,125,125,0.2)',
          borderRadius: '20px ',
          marginTop: '10px',
        }}
      >
        {[...Array(5)].map((star, index) => {
          const ratingValue = index + 1

          return (
            <label key={index} style={{ background: 'transparent' }}>
              <input
                type="radio"
                name="rating"
                value={ratingValue}
                onClick={() => handleStarClick(ratingValue)}
                style={{ display: 'none', background: 'transparent' }}
              />
              <span
                style={{
                  cursor: 'pointer',
                  color:
                    ratingValue <= rating
                      ? '#ffc107'
                      : 'var(--tg-theme-text-color)',
                  fontSize: '2em', // добавлено для увеличения размера звездочек
                  background: 'transparent',
                }}
              >
                ★
              </span>
            </label>
          )
        })}
      </div>
      <Form.Group controlId="review" style={{ padding: '10px 5%' }}>
        <Form.Label>Комментарий (необязательно) {symbols}/200</Form.Label>
        <Form.Control
          as="textarea"
          rows={3}
          value={review}
          onChange={handleReviewChange}
          maxLength={200}
          style={{
            backgroundColor: 'rgba(125,125,125,0.2)',
            color: 'var(--tg-theme-text-color',
          }}
        />
      </Form.Group>
    </div>
  )
}

export default ReviewPage
