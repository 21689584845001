import React, { useState, useEffect } from 'react'

const AppContext = React.createContext()

const AppProvider = ({ children }) => {
  const [products, setProducts] = useState([])
  const [user, setUser] = useState(null)
  const [balance, setBalance] = useState(0)
  const [supercellEmail, setSupercellEmail] = useState(null)
  const [role, setRole] = useState(null)

  useEffect(() => {
    const tg = window.Telegram.WebApp
    setUser(tg.initDataUnsafe?.user)
    if (user?.id) {
      fetch(`${process.env.REACT_APP_API_URL}/get-user-balance/${user.id}`, {
        headers: {
          'ngrok-skip-browser-warning': 'any-value',
        },
      })
        .then((response) => response.json())
        .then((data) => setBalance(data.balance))
        .catch((error) => console.error('Ошибка получения баланса:', error))

      fetch(`${process.env.REACT_APP_API_URL}/get-supercell-email/${user.id}`, {
        headers: {
          'ngrok-skip-browser-warning': 'any-value',
        },
      })
        .then((response) => response.json())
        .then((data) => setSupercellEmail(data.supercellEmail))
        .catch((error) =>
          console.error('Ошибка получения supercellEmail:', error)
        )

      fetch(`${process.env.REACT_APP_API_URL}/get-user-role/${user.id}`, {
        headers: {
          'ngrok-skip-browser-warning': 'any-value',
        },
      })
        .then((response) => response.json())
        .then((data) => setRole(data.role))
        .catch((error) =>
          console.error('Ошибка получения роли пользователя:', error)
        )
    }
  }, [user])

  console.log(role)
  return (
    <AppContext.Provider
      value={{ products, setProducts, user, setUser, supercellEmail, role }}
    >
      {children}
    </AppContext.Provider>
  )
}

export { AppContext, AppProvider }
