import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import ConfettiExplosion from 'react-confetti-explosion'
import './GiftPage.css' // Импортируйте CSS
import zIndex from '@material-ui/core/styles/zIndex'
import { Height } from '@material-ui/icons'
import ConfettiComponent from './Confetti'

// const container = {
//   position: 'absolute',
//   top: 0,
//   right: 0,
//   bottom: 0,
//   left: 0,
//   zIndex: 0,
//   backgroundColor: 'transparent',
// }

// const button = {
//   width: '100px',
//   Height: '100px',
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translateY(-50%) translateX(-50%)',
//   padding: '20px 40px',
//   color: 'white',
//   fontFamily: 'sans-serif',
//   textTransform: 'uppercase',
//   letterSpacing: 1,
//   fontWeight: 'bold',
//   border: '2px solid white',
//   borderRadius: 4,
//   boxShadow: '0 0 20px black',
//   cursor: 'pointer',
// }

// const source = {
//   position: 'absolute',
//   right: '50%',
//   left: '50%',
//   bottom: 50,
// }
// const bigExplodeProps = {
//   force: 0.6,
//   duration: 5000,
//   particleCount: 200,
//   floorHeight: 100,
//   floorWidth: 100,
// }

// const littleExplodeProps = {
//   force: 0.4,
//   duration: 3000,
//   particleCount: 60,
//   floorHeight: 1000,
//   floorWidth: 1000,
// }

// const tinyExplodeProps = {
//   force: 0.4,
//   duration: 2000,
//   particleCount: 30,
//   floorHeight: 500,
//   floorWidth: 300,
// }

// const Button = () => {
//   const [isExploding, setIsExploding] = React.useState(true)
//   return (
//     <div style={container}>
//       {isExploding && (
//         <div style={source}>
//           <ConfettiExplosion {...bigExplodeProps} />
//         </div>
//       )}
//     </div>
//   )
// }
const GiftPage = () => {
  const navigate = useNavigate()

  return (
    <div>
      <h1>Акции</h1>
      <div className="image-container">
        <div className="image-block">
          <img
            src="./gift2.webp"
            alt="Image 1"
            onClick={() => navigate('/profile')}
          />
        </div>
        <div className="image-block">
          <img
            src="./gift1.webp"
            alt="Image 2"
            onClick={() => navigate('/profile')}
          />
        </div>
      </div>
    </div>
  )
}

export default GiftPage
