import React, { useState, useEffect, useContext } from 'react'
import './ProductList.css'
import ProductItem from '../ProductItem/ProductItem'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AppContext } from '../../context/AppProvider'
import { useParams } from 'react-router-dom'
import SVGSpinner from '../Spinner/SVGSpinner'
import WarningIcon from '../../icons/WarningIcon'

const ProductList = ({ searchTerm }) => {
  const { products, setProducts } = useContext(AppContext)
  const [dropdownTitle, setDropdownTitle] = useState('по популярности')
  const { game } = useParams()
  const [isLoading, setIsLoading] = useState(true)
  // const [currentPage, setCurrentPage] = useState(1)
  // const productsPerPage = 2
  console.log(game)

  window.Telegram.WebApp.MainButton.hide()

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true)
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/products/${game || ''}`,
          {
            headers: {
              'ngrok-skip-browser-warning': 'any-value',
            },
          }
        )
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`)
        }
        const data = await response.json()
        setProducts(data.filter((product) => !product.isHidden))
      } catch (error) {
        console.error('Ошибка получения товаров:', error)
      }
      setIsLoading(false)
    }
    setProducts([])
    fetchProducts()
  }, [searchTerm, setProducts, game])

  const handleSelect = (eventKey) => {
    setDropdownTitle(eventKey)
  }

  let displayedProducts = [...products]

  switch (dropdownTitle) {
    case 'по популярности':
      // предполагая, что у вас есть поле `popularity` в данных продукта
      const onSaleProducts = products
        .filter((product) => product.isOnSale)
        .sort((a, b) => b.purchases - a.purchases)

      const otherProducts = products
        .filter((product) => !product.isOnSale)
        .sort((a, b) => b.purchases - a.purchases)

      displayedProducts = [...onSaleProducts, ...otherProducts]
      // displayedProducts.sort((a, b) => b.purchases - a.purchases)
      break
    case 'по возрастанию цены':
      displayedProducts.sort((a, b) => a.price - b.price)
      break
    case 'по убыванию цены':
      displayedProducts.sort((a, b) => b.price - a.price)
      break
    default:
      break
  }

  if (searchTerm || game) {
    displayedProducts = displayedProducts.filter((product) =>
      product.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    displayedProducts = displayedProducts.filter((product) =>
      product.game.toLowerCase().includes(searchTerm.toLowerCase())
    )
  }

  // const paginate = (pageNumber) => setCurrentPage(pageNumber)

  // const nextPage = () => {
  //   const maxPage = Math.ceil(products.length / productsPerPage)
  //   if (currentPage < maxPage) {
  //     setCurrentPage(currentPage + 1)
  //   }
  // }

  // const prevPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1)
  //   }
  // }

  return (
    <div className="d-flex flex-column" style={{ paddingBottom: '60px' }}>
      <div className="d-flex justify-content-between align-items-center header-container">
        <h1 className="game-title">Товары</h1>
        <Dropdown
          onSelect={handleSelect}
          className="custom-font-size"
          style={{ padding: '0' }}
        >
          <Dropdown.Toggle
            id="dropdown-basic"
            style={{
              backgroundColor: 'var(--tg-theme-bg-color)',
              borderColor: 'transparent',
              color: 'var(--tg-theme-text-color)',
            }}
          >
            {dropdownTitle}
          </Dropdown.Toggle>

          <Dropdown.Menu
            className="custom-font-size"
            style={{
              backgroundColor: 'var(--tg-theme-secondary-bg-color)',
              borderColor: 'transparent',
              color: 'var(--tg-theme-text-color)',
            }}
          >
            <Dropdown.Item
              className="dropdown-item"
              eventKey="по популярности"
              style={{
                color: 'var(--tg-theme-text-color)',
              }}
            >
              по популярности
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="по возрастанию цены"
              style={{
                color: 'var(--tg-theme-text-color)',
              }}
            >
              по возрастанию цены
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="по убыванию цены"
              style={{
                color: 'var(--tg-theme-text-color)',
              }}
            >
              по убыванию цены
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="list">
        {isLoading ? (
          <SVGSpinner />
        ) : displayedProducts.length === 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
              paddingBottom: '150px',
            }}
          >
            <WarningIcon />
            <p>К сожалению, товары не найдены :(</p>
          </div>
        ) : (
          displayedProducts.map((item) => (
            <Link
              to={`/product/${item.id}`}
              key={item.id}
              className="no-underline"
            >
              <ProductItem product={item} className={'item'} />
            </Link>
          ))
        )}
      </div>
    </div>
  )
}

export default ProductList
